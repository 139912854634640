import styled from "styled-components";

const StyledFooter = styled.footer`
  height: 240px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Roboto";
  
  @media all and (max-width: 1200px) {
    height: 320px;
  }
`;

const FooterInfo = styled.div`
  border-top: 1px solid #3a3a3a;
  padding-top: 60px;
  display: flex;
  justify-content: space-around;
  
  @supports not (-ms-ime-align: auto) {
    justify-content: space-evenly;
  }
  
/*  img {
    height: 28px;
    width: auto;
    margin-top: 10px;
  }*/
  
  @media all and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    
    & > * {
      margin-bottom: 20px;
    }
  }
  
  @media all and (max-width: 800px) {
    padding-top: 15px;
      
    img {
      height: 22px;
    }
  }
`;

const Copyright = styled.div`
  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    padding-bottom: 30px;
    color: #424242;
    font-weight: 300;
    text-transform: uppercase;
  }
`;

const Address = styled.div`
  text-align: left;
  
  pre {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-family: "Roboto";
    color: #424242;
    
    a {
      font-size: 1.6rem;
      text-decoration: none;
      color: black;
    }
  }
  
  p {
    margin-top: 2.2rem;
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 2.4rem;
    font-family: "Roboto";
    color: #3A3A3A;
  }
  
  @media all and (max-width: 1200px) {
    text-align: center;
    width: 100%;
    padding: 0 25px;
  }
`;

const PoweredBy = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  border-left: 1px solid rgba(58,58,58,0.4);
  padding-left: 56px;

  span {
    text-transform: uppercase;
    font-size: 1.2rem;
    line-height: 2.4rem;
    letter-spacing: 2.6px;
    font-family: "Roboto";
  }
  
  img {
    height: 28px;
    width: auto;
  }
  
  @media all and (max-width: 1200px) {
    width: 100%;
    text-align: center;
    border-left: none;
    padding: 0;
  }
`;

const StyledHeader = styled.header`
  display: block;
  margin: 0 auto;
  //height: 130px;
  height: fit-content;
  ${({ noPadding }) => noPadding ? 'padding: 0;' : 'padding: 0 25px;'};
  
  //@media all and (max-width: 800px) {
  //  height: 100px;
  //}
  
  a {
    text-decoration: none;
    color: black;
    
    p {
      font-size: 6rem;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 3px;
      
      @media all and (max-width: 768px) {
        font-size: 4rem;
      }
    }
  }
`;

const HeaderLogoContainer = styled.div`
  padding: 20px 0;
  height: 100%;
  border-bottom: 1px solid #3A3A3A;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    width: auto;
    height: 38px;
  }
  
  @media all and (max-width: 800px) {
    img {
      height: 24px;
    }
  }
`;

const LogosContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  & > a {
    display: block;
    img {
      height: 100px;
      width: auto;
      margin: 0 5px;
    }
  }
`;

export {
  StyledFooter,
  FooterInfo,
  Copyright,
  Address,
  PoweredBy,
  StyledHeader,
  HeaderLogoContainer,
  LogosContainer,
}
