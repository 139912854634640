import React from 'react';
import { Link } from 'gatsby';
import { StyledHeader, HeaderLogoContainer } from './styled';

const Header = ({ noPadding, assetsPath }) => (
  <StyledHeader noPadding={noPadding}>
      <Link to={`/`}>
          <HeaderLogoContainer>
              <img src={`${assetsPath}/logotypes.png`} alt="Artistellar FOA logotypes"/>
          </HeaderLogoContainer>
      </Link>
  </StyledHeader>
);

export default Header;
